import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from '../admin/store/index.js'
import ElementUI from 'element-ui'
import i18n from '@/lang'
import 'lib-flexible/flexible.js'
// import '../permission.js'

// import '@unocss/reset/normalize.css'
// import 'uno.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css'
import WOW from 'wow.js'

import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/css/swiper.css'
// import '@/assets/js/swiper.animate1.0.3.min'

Vue.use(VueAwesomeSwiper /* { default options with global component } */)

// Vue.use(animated)

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 0, // default
  mobile: true, // default
  live: true,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function (box) {
    console.log('WOW: animating <' + box.tagName.toLowerCase() + '>')
  }
})

new Vue({
  router,
  // store,
  i18n,
  render: h => h(App)
}).$mount('#app')
