<template>
  <div id="app">
    <router-view />

    <div v-if="showCookie" class="cookie">
      <div class="cookie-content">
        <div class="cookie-text">
          <img src="@/assets/icon/icon-cookie-warning.svg" alt="" />
          <div class="text">
            <div class="title">{{ $t('cookie.title') }}</div>
            <div class="desc">
              {{ $t('cookie.description') }}
            </div>
          </div>
        </div>
        <div class="cookie-btn">
          <div class="btn btn-default" @click="handleReject">{{ $t('global.reject') }}</div>
          <div class="btn btn-blue" @click="handleAccept">{{ $t('global.acceptAll') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { emitter } from '@/utils/mittEvent'

export default {
  components: {},
  data() {
    return {
      showCookie: false
    }
  },
  methods: {
    handleReject() {
      Cookies.set('AcceptCookies', false)
      this.showCookie = false
    },
    handleAccept() {
      Cookies.set('AcceptCookies', true)
      this.showCookie = false
    }
  },
  mounted() {
    emitter.on('showcookie', () => {
      console.log('showcookie')
      Cookies.get('AcceptCookies') === 'false'
      this.showCookie = true
    })
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
      location.href = '/mobile.html'
    }

    console.log(typeof Cookies.get('AcceptCookies'))

    if (Cookies.get('AcceptCookies') === 'true') {
      this.showCookie = false
    } else {
      this.showCookie = true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/fonts/Poppins/stylesheet.css';
@import '@/assets/fonts/AlibabaPuHuiTi/stylesheet.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  // transition: all 0.25s;
  font-family: Poppins, 'AlibabaPuHuiTi', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @import '@/assets/response.scss';

.cookie {
  width: 100%;
  background: #f2f2f2;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;

  .cookie-content {
    max-width: 1634px;
    margin: 0 auto;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-text {
    display: flex;
    align-items: center;
    gap: 25px;

    img {
      width: 26px;
      height: 24px;
    }
    .title {
      font-family: Poppins;
      font-weight: 300;
      font-size: 22px;
      color: #000000;
      line-height: 22px;
    }
    .desc {
      margin-top: 10px;
      max-width: 800px;
      font-family: Poppins;
      font-weight: 300;
      font-size: 16px;
      line-height: 1;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  &-btn {
    display: flex;
    gap: 25px;

    .btn {
      width: 146px;
      height: 34px;
      border-radius: 17px;
      border: 2px solid #000000;

      font-family: Poppins;
      font-weight: 300;
      font-size: 16px;
      color: #000000;
      line-height: 30px;
      text-align: center;

      cursor: pointer;

      &.btn-blue {
        background: rgba(0, 91, 172, 1);
        color: #fff;
      }
    }
  }
}

@-webkit-keyframes customInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes customInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.custom__customInLeft {
  -webkit-animation-name: customInLeft;
  animation-name: customInLeft;
}
@-webkit-keyframes customInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes customInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.custom__customInRight {
  -webkit-animation-name: customInRight;
  animation-name: customInRight;
}

@-webkit-keyframes cflipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  50% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes cflipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  50% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.custom__flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: cflipInX;
  animation-name: cflipInX;
}
</style>
